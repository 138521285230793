import React, { useState, useEffect, useRef } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
    Box,
    Slide,
    Button,
    TextField,
    Typography,
    FormGroup,
    FormControlLabel,
    FormControl,
    FormHelperText,
    Checkbox,
    Link,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import InputAdornment from '@mui/material/InputAdornment'
import {
    createStationMeasurement,
    setBrokenStation,
    deleteRecentMeasurements,
} from '../../actions'
import { useSessionMeausrementsContext } from '../../../context/SessionMeasurementsContext'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Plot from 'react-plotly.js'
import { useToastContext } from '../../../context/ToastContext'
import localforage from 'localforage'
import { styles } from '../../../theme/styles'
import { ReactComponent as DangerIcon } from '../../../icons/danger.svg'
import { FaRegComment } from 'react-icons/fa'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'

//Displays data for a station and allows users to add measurments
function IndividualStation({
    id,
    index,
    stationTitle,
    stationDescription,
    target,
    rangeLow,
    rangeHigh,
    possibleRangeLow,
    possibleRangeHigh,
    measurementUnit,
    broken,
    isReorderingStations,
    zoneId,
    stationId,
    zoneTitle,
    measurementOptions,
    stationMeasurements,
    virtualizedListStyles,
    setSize,
    masterLogsheetId,
    logsheetId,
    isStationIdInUrl,
    submittedStations,
    onSubmit,
    isActive,
    onClick,
    justSubmittedStationId,
}) {
    const location = useLocation()
    const { addSessionMeasurement } = useSessionMeausrementsContext()
    const sliderContainerRef = useRef(null)
    const [stationEntry, setStationEntry] = useState(null)
    const [submittedTime, setSubmittedTimeState] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [submitButtonVisible, setSubmitButtonVisible] = useState(false)
    const [recentMeasurements, setRecentMeasurements] =
        useState(stationMeasurements)
    const toastContext = useToastContext()
    const [openCommentModal, setOpenCommentModal] = useState(false)
    const [comment, setComment] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [alreadySubmittedArray, setAlreadySubmittedArray] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [graphMeasurements, setGraphMeasurements] = useState([])

    const targetNumber = parseFloat(target)
    const rangeLowNumber = parseFloat(rangeLow)
    const rangeHighNumber = parseFloat(rangeHigh)
    const possibleRangeLowNumber = parseFloat(possibleRangeLow)
    const possibleRangeHighNumber = parseFloat(possibleRangeHigh)

    const [isBroken, setIsBroken] = useState(broken)

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: id,
        disabled: isReorderingStations ? false : true,
    })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const sizeRef = useRef(null)
    const stationRef = useRef(null)
    const inputRef = useRef(null)
    const brokenWait = useRef(false)

    const isMultipleChoiceStation = measurementOptions?.length > 0

    React.useEffect(() => {
        if (sizeRef.current)
            setSize(index, sizeRef.current.getBoundingClientRect().height)
    }, [stationEntry])

    const currentSubmittedStation = submittedStations?.find(
        (station) => station.stationId === id
    )

    async function handleDelete(event) {
        event.preventDefault()

        const measurementId = currentSubmittedStation.measurementId
        setStationEntry(null)

        await deleteRecentMeasurements(zoneId, stationId, measurementId)
            .then(() => {
                toastContext.sendToast('Successfully Deleted Measurement')
                setAlreadySubmittedArray((prev) => [
                    ...prev.filter((station) => station.stationId !== id),
                ])
            })
            .catch((e) => toastContext.sendToast(e.message, 'error'))
    }

    const submitMeasurement = () => {
        // Pass both value and comment to parent
        onSubmit(id, stationEntry, comment);
        setOpenCommentModal(false);
        setComment(null);
        setStationEntry('');
        setIsEditing(false);
    };

    const isMeasurementTaken = () => {
        return {
            taken: !!currentSubmittedStation?.value,
            value: currentSubmittedStation?.value || ''
        };
    };

    async function handleSubmit(event) {
        event.preventDefault()

        if (!submitted) {
            if (
                !/^[-+]?[0-9]*\.?[0-9]+$/.test(stationEntry) &&
                !isMultipleChoiceStation
            ) {
                toastContext.sendToast(
                    'data entry for ' +
                        stationTitle +
                        ' is not a properly formated number',
                    'error'
                )
                return
            }

            if (
                stationEntry < rangeLowNumber ||
                stationEntry > rangeHighNumber
            ) {
                setOpenCommentModal(true)
                return
            }

            await submitMeasurement()
        }
    }

    //Called when the broken box is clicked. Timer is used to make sure the checkbox can't be spamed
    async function brokenClicked(brokenVal) {
        if (brokenWait.current) {
            return
        }
        setIsBroken(brokenVal)
        brokenWait.current = true

        await updateLocalforageBrokenStation(brokenVal)
        setBrokenStation(zoneId, id, brokenVal)
            .then(() => {
                const toastMessage = brokenVal
                    ? 'Instrument is broken'
                    : 'Instrument is not broken'
                const toastColor = brokenVal ? 'warning' : 'success'
                toastContext.sendToast(toastMessage, toastColor)
            })
            .catch((e) => toastContext.sendToast(e.message, 'error'))

        setTimeout(() => {
            brokenWait.current = false
        }, 3000)
    }

    //Used to update the localforage version of the station when a station is marked as broken
    async function updateLocalforageBrokenStation(brokenVal) {
        const allMasterLogsheets = await localforage.getItem('allLogsheets')
        if (!allMasterLogsheets) {
            return
        }

        const fMasterLogsheet = allMasterLogsheets.find(
            (masterLogsheet) => +masterLogsheet.id === +masterLogsheetId
        )

        if (fMasterLogsheet) {
            const fLogsheet = fMasterLogsheet.logsheets.find(
                (logsheet) => +logsheet.id === +logsheetId
            )

            if (fLogsheet) {
                const fStation = fLogsheet.stations.find(
                    (station) => +station.stationId === +id
                )

                if (fStation) {
                    fStation.broken = brokenVal
                    await localforage.setItem(
                        'allLogsheets',
                        allMasterLogsheets
                    )
                }
            }
        }
    }

    useEffect(() => {
        if (submitted) {
            setTimeout(() => {
                setSubmitted(false)
            }, 0)
        }
    }, [stationEntry])

    const setIndexNum = async () => {
        localforage.setItem('IndexNum' + logsheetId, index)
    }

    //When a station is clicked the URL is updated
    const updateURL = () => {
        const newUrl = `/logsheets/${masterLogsheetId}/logsheet/${logsheetId}/${id}`
        window.history.pushState(null, '', newUrl)
    }

    const isOutOfRange =
        currentSubmittedStation && !currentSubmittedStation?.withinLimit

    const isOutOfRangeEntered =
        stationEntry &&
        (stationEntry < rangeLowNumber || stationEntry > rangeHighNumber)

    const getSuccessUrl = () => {
        if (isStationIdInUrl) {
            // Replace the last part of the path (current stationId) with the new id
            const pathParts = location.pathname.split('/')
            pathParts[pathParts.length - 1] = id
            return pathParts.join('/')
        } else {
            // Append the new stationId to the current path
            return `${location.pathname}/${id}`
        }
    }

    useEffect(() => {
        // When a station has been submitted, store it in localStorage
        if (currentSubmittedStation) {
            localStorage.setItem(
                `measurement_${logsheetId}_${id}`,
                JSON.stringify({
                    value: currentSubmittedStation.value,
                    timestamp: new Date().getTime()
                })
            )
        }
    }, [currentSubmittedStation, logsheetId, id])

    // Update the check to use both sources
    const isStationSubmitted = currentSubmittedStation || isMeasurementTaken().taken
    
    const handleInputChange = (e) => {
        setStationEntry(e.target.value)
    }
    
    // Function to handle editing
    const handleEditClick = () => {
        if (currentSubmittedStation) {
            setStationEntry(currentSubmittedStation.value);
            setIsEditing(true);
        }
        // onSubmit(id, null, null);
        // localStorage.removeItem(`measurement_${logsheetId}_${id}`);
        // setSubmittedTimeState(null);
        // setAlreadySubmittedArray((prev) => [
        //     ...prev.filter((station) => station.stationId !== id),
        // ])
    };

    // Function to handle saving the measurement
    const handleSave = async (e) => {
        if (e) {
            e.preventDefault(); // Prevent default form submission
        }
        
        console.log('handleSave called');
        try {
            // Check if the value hasn't changed
            if (stationEntry === currentSubmittedStation?.value?.toString()) {
                setIsEditing(false);  // Just exit edit mode
                return;
            }

            setSubmitting(true);
            const numValue = parseFloat(stationEntry);

            if (isNaN(numValue)) {
                toastContext.sendToast('Please enter a valid number', 'error');
                return;
            }

            if (numValue < rangeLowNumber || numValue > rangeHighNumber) {
                setOpenCommentModal(true);
                return;
            }
            console.log('Saving measurement:', {
                stationId: id,
                value: stationEntry
            });

            // Send measurement up to IndividualLogsheet
            onSubmit(id, stationEntry);
            
            // Clear input and exit edit mode
            setStationEntry('');
            setIsEditing(false);

            // Prevent scroll reset by maintaining the current scroll position
            const currentScroll = window.scrollY;
            setTimeout(() => {
                window.scrollTo({
                    top: currentScroll,
                    behavior: 'instant'
                });
            }, 0);

        } catch (error) {
            console.error('Error saving measurement:', error);
            toastContext.sendToast('Error saving measurement', 'error');
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        // Copy recent measurements to graphMeasurements
        const updatedGraphMeasurements = [...recentMeasurements];

        // Check for recent submission in localStorage
        const recentSubmission = localStorage.getItem(`measurement_${logsheetId}_${id}`);
        if (recentSubmission) {
            const parsedSubmission = JSON.parse(recentSubmission);
            const submissionTime = new Date(parsedSubmission.timestamp);
            const currentTime = new Date();

            // Check if the submission is within the last 60 minutes
            if ((currentTime - submissionTime) <= 60 * 60 * 1000) {
                // If the submission is recent, add it to the graph measurements
                updatedGraphMeasurements.push({
                    measurementValue: parsedSubmission.value,
                    createdAt: parsedSubmission.timestamp,
                });
            }
        }

        // Sort measurements by createdAt and take the 10 most recent
        const sortedMeasurements = updatedGraphMeasurements
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .slice(-10); // Get the last 10 measurements

        // Set the graph measurements
        setGraphMeasurements(sortedMeasurements);
    }, [recentMeasurements, id]);

    const handleClose = () => {
        setOpenCommentModal(false);
    };

    useEffect(() => {
        console.log(`Station ${index} is ${isActive ? 'active' : 'inactive'}`);
    }, [isActive]);

    // Add this useEffect to handle input focus
    useEffect(() => {
        if (
            isActive && 
            inputRef.current && 
            !currentSubmittedStation?.value && // No previous submission
            !isEditing // Not in edit mode
        ) {
            inputRef.current.focus();
        }
    }, [isActive, currentSubmittedStation, isEditing]);

    return (
        <Box
            // px={3}
            // mb={2}
            key={stationId}
            ref={setNodeRef}
            style={{
                ...style,
                ...virtualizedListStyles,
                background: 'transparent',
            }}
            {...attributes}
            {...listeners}
            onClick={(e) => {
                // Prevent double-firing of click events
                e.stopPropagation();
                onClick?.();
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    background: 'white',
                    borderRadius: '12px',
                    padding: '15px 35px',
                    width: '93%',
                    minWidth: '200px',
                    border: isActive ? '2px solid #763DE2' : 'none',
                    boxShadow: isActive ? '1px 1px 2px 2px #763DE2' : 'none',
                    //   minHeight: '240px',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                }}
                ref={stationRef}
            >
                <div style={styles.cardLogsheetStationInfo}>
                    <div style={styles.cardTextLogsheetRedBlockWrap}>
                        {isBroken && (
                            <div style={styles.cardTextLogsheetRedBlock}>
                                <DangerIcon
                                    style={{
                                        cursor: 'pointer',
                                        width: '30px',
                                    }}
                                />
                                <div style={styles.cardTextStationSmallRed}>
                                    Instrument broken
                                </div>
                            </div>
                        )}
                    </div>

                    <div>
                        <div style={styles.cardTextBig}>
                            {stationTitle}{' '}
                            {isMeasurementTaken().taken && (
                                <span
                                    style={{
                                        color: 'green',
                                        marginLeft: '4px',
                                    }}
                                >
                                    ✓✓
                                </span>
                            )}
                        </div>

                        <div style={styles.cardTextSmallBold}>
                            {stationDescription}
                        </div>

                        {currentSubmittedStation?.submittedTime && (
                            <div style={{ marginTop: '10%' }}>
                                <div style={styles.cardTextSmall}>
                                    Last Submitted at:
                                </div>
                                <div style={styles.cardTextSmallBold}>
                                    {new Date(currentSubmittedStation.submittedTime).toLocaleString()}
                                </div>
                            </div>
                        )}
                    </div>

                    {submittedTime && (
                        <div>
                            <div style={styles.cardTextSmall}>
                                Last Submitted at:
                            </div>
                            <div style={styles.cardTextSmallBold}>
                                {new Date(submittedTime).toLocaleString()}
                            </div>
                        </div>
                    )}
                </div>

                <Box
                    sx={{
                        minWidth: '250px',

                        backgroundColor:
                            isOutOfRangeEntered && !isOutOfRange && '#ef233c',
                        borderRadius: '20px',
                        padding: '5px 10px',
                        paddingBottom: '5px',
                    }}
                    ref={sizeRef}
                >
                    {isOutOfRangeEntered && !isOutOfRange && (
                        <Typography
                            sx={{
                                color: '#ffffff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                paddingBottom: '5px',
                                textAlign: 'center',
                            }}
                        >
                            {stationEntry &&
                                (possibleRangeLowNumber &&
                                stationEntry < possibleRangeLowNumber
                                    ? `BELOW THE POSSIBLE RANGE LOW!`
                                    : possibleRangeHighNumber &&
                                      stationEntry > possibleRangeHighNumber
                                    ? `ABOVE THE POSSIBLE RANGE HIGH!`
                                    : stationEntry < rangeLowNumber
                                    ? `BELOW THE RANGE!`
                                    : stationEntry > rangeHighNumber
                                    ? `ABOVE THE RANGE!`
                                    : '')}
                        </Typography>
                    )}
                    {target && (
                        <div
                            style={{
                                ...styles.cardTextSmallerBlockLogsheet,
                                color:
                                    isOutOfRangeEntered &&
                                    !isOutOfRange &&
                                    'white',
                            }}
                        >
                            <div style={styles.cardTextSmall}>Target:</div>
                            <div style={styles.cardTextSmallBold}>
                                {targetNumber} {measurementUnit}
                            </div>
                        </div>
                    )}
                    {rangeLow && rangeHigh ? (
                        <div
                            style={{
                                ...styles.cardTextSmallerBlockLogsheet,
                                color:
                                    isOutOfRangeEntered &&
                                    !isOutOfRange &&
                                    'white',
                            }}
                        >
                            <div style={styles.cardTextSmall}>Range:</div>
                            <div style={styles.cardTextSmallBold}>
                                {rangeLowNumber}
                                {measurementUnit} - {rangeHighNumber}
                                {measurementUnit}
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                ...styles.cardTextSmallerBlockLogsheet,
                                color:
                                    isOutOfRangeEntered &&
                                    !isOutOfRange &&
                                    'white',
                            }}
                        >
                            <div style={styles.cardTextSmall}>
                                {' '}
                                {rangeLow || rangeHigh ? 'Range:' : ''}
                            </div>
                            <div style={styles.cardTextSmallBold}>
                                {!!rangeLowNumber ? `>  ${rangeLowNumber}` : ''}
                                {!!rangeHighNumber
                                    ? `<  ${rangeHighNumber}`
                                    : ''}{' '}
                                {!!rangeLowNumber || !!rangeHighNumber
                                    ? measurementUnit
                                    : ''}
                            </div>
                        </div>
                    )}
                    {/* Display for non-multiple choice stations */}
                    {!isMultipleChoiceStation && (
                        <Box paddingTop="10px" display="flex" alignItems="center" gap={1}>
                            <TextField
                                id={`station-input-${stationId}`}
                                borderRadius={'19px'}
                                inputRef={inputRef}
                                variant="outlined"
                                value={stationEntry || ''}
                                onChange={handleInputChange}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // Prevent default form submission
                                        console.log('Enter key pressed');
                                        handleSave();
                                    }
                                }}
                                placeholder={currentSubmittedStation?.value || "measured value"}
                                color={
                                    stationEntry
                                        ? stationEntry < rangeLowNumber ||
                                          stationEntry > rangeHighNumber
                                            ? 'error'
                                            : 'success'
                                        : 'primary'
                                }
                                InputProps={{
                                    readOnly: currentSubmittedStation?.value && !isEditing,
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <span style={{ color: isOutOfRangeEntered ? 'black' : 'inherit' }}>
                                                {measurementUnit}
                                            </span>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: isOutOfRangeEntered && 'black',
                                    },
                                }}
                                style={{
                                    backgroundColor: currentSubmittedStation?.value && !isEditing ? '#d3d3d3' : 'white', // light grey
                                    width: '70%', // Adjust the width as needed
                                }}
                                sx={{ border: 0 }}
                            />
                            <Button
                                onClick={() => {
                                    if (isEditing || !currentSubmittedStation?.value) {
                                        handleSave();
                                    } else {
                                        handleEditClick();
                                    }
                                }}
                                disabled={submitting}
                                variant="contained"
                                color="primary"
                            >
                                {isEditing || !currentSubmittedStation?.value ? 'Save' : 'Edit'}
                            </Button>
                        </Box>
                    )}
                    {/* Display for non-multiple choice AFTER a value is submitted */}
                    {!isMultipleChoiceStation &&
                        alreadySubmittedArray.some(
                            (station) => station.stationId === id
                        ) && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="start"
                                paddingTop="7px"
                            >
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span style={{ color: 'green' }}>✓</span>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {measurementUnit}
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{
                                        width: '100%',
                                    }}
                                    helperText={
                                        currentSubmittedStation.value &&
                                        (currentSubmittedStation.value <
                                        rangeLowNumber
                                            ? `${currentSubmittedStation.value} is below the range`
                                            : currentSubmittedStation.value >
                                              rangeHighNumber
                                            ? `${currentSubmittedStation.value} is above the range`
                                            : '')
                                    }
                                    error={isOutOfRange}
                                    color={isOutOfRange ? 'error' : 'success'}
                                    value={currentSubmittedStation.value}
                                />
                                <Button
                                    style={{
                                        marginTop: '.5rem',
                                        marginLeft: '.1rem',
                                    }}
                                    sx={{
                                        backgroundColor: '#333',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#555',
                                        },
                                    }}
                                    size="large"
                                    variant="contained"
                                    onClick={(e) => handleDelete(e)}
                                    startIcon={<DeleteIcon />}
                                >
                                    DELETE ENTRY
                                </Button>
                            </Box>
                        )}
                    {/* Display for multiple choice BEFORE a value is submitted */}
                    {isMultipleChoiceStation &&
                        !alreadySubmittedArray.some(
                            (station) => station.stationId === id
                        ) && (
                            <FormGroup
                                style={{
                                    zIndex: 2,
                                    position: 'relative',
                                }}
                                ref={inputRef}
                                onBlur={(e) => {
                                    setSubmitButtonVisible(false)
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit(e)
                                        setSubmitButtonVisible(false)
                                    }
                                }}
                            >
                                {measurementOptions.map((item) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        color:
                                                            isOutOfRangeEntered &&
                                                            'white',
                                                        '&.Mui-checked': {
                                                            color:
                                                                isOutOfRangeEntered &&
                                                                'white',
                                                        },
                                                        '& .MuiFormControlLabel-label':
                                                            {
                                                                color: 'white',
                                                            },
                                                    }}
                                                    checked={
                                                        item === stationEntry
                                                    }
                                                    onClick={(e) => {
                                                        setStationEntry(item)
                                                        setSubmitButtonVisible(
                                                            true
                                                        )
                                                    }}
                                                />
                                            }
                                            label={
                                                <span
                                                    style={{
                                                        color:
                                                            isOutOfRangeEntered &&
                                                            'white',
                                                    }}
                                                >
                                                    {item}
                                                </span>
                                            }
                                        />
                                    )
                                })}
                            </FormGroup>
                        )}
                    {/* Display for multiple choice AFTER a value is submitted */}
                    {isMultipleChoiceStation &&
                        alreadySubmittedArray.some(
                            (station) => station.stationId === id
                        ) && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="start"
                            >
                                <FormControl
                                    component="fieldset"
                                    variant="standard"
                                    error={{ isOutOfRange }}
                                >
                                    <FormGroup
                                        style={{
                                            zIndex: 2,
                                            backgroundColor: 'white',
                                            position: 'relative',
                                        }}
                                        ref={inputRef}
                                    >
                                        {measurementOptions.map((item) => {
                                            return (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                item ===
                                                                currentSubmittedStation.value
                                                            }
                                                            disabled={
                                                                item !==
                                                                currentSubmittedStation.value
                                                            }
                                                            style={{
                                                                color:
                                                                    item ===
                                                                        currentSubmittedStation.value &&
                                                                    'black',
                                                            }}
                                                        />
                                                    }
                                                    label={item}
                                                />
                                            )
                                        })}
                                    </FormGroup>
                                    <FormHelperText>
                                        {currentSubmittedStation.value &&
                                            (currentSubmittedStation.value <
                                            rangeLowNumber
                                                ? `${currentSubmittedStation.value} is below the range`
                                                : currentSubmittedStation.value >
                                                  rangeHighNumber
                                                ? `${currentSubmittedStation.value} is above the range`
                                                : '')}
                                    </FormHelperText>
                                </FormControl>
                                <Button
                                    style={{
                                        marginTop: '.5rem',
                                        marginLeft: '.1rem',
                                    }}
                                    sx={{
                                        backgroundColor: '#333',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#555', // Color on hover
                                        },
                                    }}
                                    size="large"
                                    variant="contained"
                                    onClick={(e) => handleDelete(e)}
                                >
                                    DELETE ENTRY
                                </Button>
                            </Box>
                        )}

                    <Box
                        ref={sliderContainerRef}
                        style={{ visibility: 'none' }}
                    ></Box>
                    <Slide
                        in={submitButtonVisible}
                        container={sliderContainerRef.current}
                        direction="down"
                        mountOnEnter
                        unmountOnExit
                        style={{ zIndex: 1 }}
                    >
                        <Button
                            style={{
                                marginTop: '.5rem',
                                marginBottom: '.5rem',
                                marginLeft: '.1rem',
                                backgroundColor: isOutOfRangeEntered ? '#b90923' : '#363636',
                            }}
                            size="large"
                            variant="contained"
                            disabled={submitting}
                            onMouseDown={(e) => {
                                setSubmitButtonVisible(false)
                                handleSubmit(e)
                            }}
                        >
                            {submitting ? 'Saving...' : (
                                stationEntry &&
                                (stationEntry < rangeLowNumber || stationEntry > rangeHighNumber)
                                    ? `SAVE ANYWAYS`
                                    : 'SAVE'
                            )}
                        </Button>
                    </Slide>
                </Box>

                {isMultipleChoiceStation
                    ? recentMeasurements && (
                          <Plot
                              key={recentMeasurements.length}
                              data={[
                                  {
                                      x: recentMeasurements.map(
                                          (measurement) =>
                                              measurement.measurementValue
                                      ),
                                      type: 'histogram',
                                      marker: {
                                          color: [
                                              '#5ED6C8',
                                              '#7B7EDF',
                                              '#009197',
                                              '#CFA9EB',
                                              '#FFDB7C',
                                              '#7FAAD4',
                                          ], // Alternating colors
                                          line: {
                                              width: 0, // Remove line borders between bars
                                          },
                                      },
                                      hoverinfo: 'x+y',
                                  },
                              ]}
                              layout={{
                                  width: 400,
                                  height: 200,
                                  title: '',
                                  autosize: true,
                                  showlegend: false,
                                  hidesources: false,
                                  margin: {
                                      l: 30,
                                      r: 0,
                                      b: 30,
                                      t: 0,
                                      pad: 0,
                                  },
                                  bargap: 0.7, // Adjust the gap between bars

                                  xaxis: {
                                      type: 'category', // Use 'category' type for discrete values
                                      tickvals: recentMeasurements.map(
                                          (measurement) =>
                                              measurement.measurementValue
                                      ), // Use measurement values as tick positions
                                      showticklabels: true, // Show tick labels
                                  },
                              }}
                              config={{
                                  displayModeBar: false,
                                  staticPlot: true,
                                  fillFrame: true,
                                  responsive: true,
                              }}
                          />
                      )
                    : recentMeasurements && (
                          <Plot
                              key={recentMeasurements.length}
                              data={[
                                  {
                                      x: graphMeasurements.map((measurement) =>
                                          new Date(measurement.createdAt).toLocaleString()
                                      ),
                                      y: graphMeasurements.map((measurement) => measurement.measurementValue),
                                      type: 'scatter',
                                      mode: 'lines+markers',
                                      marker: { color: '#2DB3B3', size: 8 },
                                      line: { color: '#9FD4D4', width: 1 },
                                  },
                              ]}
                              layout={{
                                  width: 400,
                                  height: 200,
                                  title: '',
                                  autosize: true,
                                  showlegend: false,
                                  hidesources: false,
                                  margin: {
                                      l: 30,
                                      r: 0,
                                      b: 30,
                                      t: 0,
                                      pad: 0,
                                  },
                                  xaxis: {
                                      autorange: true,
                                      type: 'category',
                                      visible: false,
                                  },
                                  yaxis: {
                                      zeroline: false, // Hide zero line
                                      tickfont: {
                                          family: 'Montserrat', // Set font family
                                          size: 12, // Set font size
                                      },
                                  },
                              }}
                              config={{
                                  displayModeBar: false,
                                  staticPlot: true,
                                  fillFrame: true,
                                  responsive: true,
                              }}
                          />
                      )}

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Link
                        component={RouterLink}
                        className="normalButton"
                        sx={{
                            textDecoration: 'none',
                        }}
                        to={{
                            pathname: `/logsheets/${masterLogsheetId}/logsheet/${logsheetId}/station/${id}/comments`,
                            state: {
                                successUrl: getSuccessUrl(),
                            },
                        }}
                        onClick={(e) => {
                            // Stop propagation only for navigation
                            e.stopPropagation();
                        }}
                    >
                        <div className="buttonIconBlock">
                            <FaRegComment size={30} />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">
                                Comments
                            </div>
                            <div className="buttonTextSmallerBlock">
                                station
                            </div>
                        </div>
                    </Link>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isBroken}
                                onChange={(e) =>
                                    brokenClicked(e.target.checked)
                                }
                                color="primary"
                                onClick={(e) => {
                                    // Stop propagation only for checkbox
                                    e.stopPropagation();
                                }}
                            />
                        }
                        label={
                            <div className="buttonTextBlock">
                                <div className="buttonTextSmallerBlock">
                                    instrument
                                </div>
                                <div className="buttonTextBiggerBlock">
                                    Broken
                                </div>
                            </div>
                        }
                        labelPlacement="end"
                    />
                </Box>

                {isReorderingStations && <DragIndicatorIcon />}
            </Box>
            <Dialog
                open={openCommentModal}
                // onClose={handleClose}
                disableEscapeKeyDown
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>
                    Out of Range Measurement Comment
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Comment"
                        fullWidth
                        variant="outlined"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                if (comment) {
                                    handleClose();
                                    submitMeasurement();
                                }
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            backgroundColor: '#000',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#333',
                            },
                        }}
                        onClick={() => {
                            handleClose();
                            submitMeasurement();
                        }}
                        disabled={!comment}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

IndividualStation.defaultProps = {
    stationTitle: '',
    id: '',
    stationDescription: '',
    target: '',
    rangeLow: '',
    rangeHigh: '',
    lastAccess: '2020-01-01T00:00:00.000Z',
}

export default IndividualStation