import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Grid, Box, useTheme, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getCurrentUser } from 'src/actions/openapi'
import GeneralSettings from './GeneralSettings'
import Password from './Password'
import OrganizationSetting from './Organization'
import QRLogin from './QRLogin'
import FieldOfWorkSettings from './FieldOfWorkSettings'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
        },
    },
    spacer: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    contentBox: {
        width: '100%',
        maxWidth: '900px',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(1),
        },
    },
}))

const General = ({ className, ...rest }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [user, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
    })

    useEffect(() => {
        async function asyncFetchProfileSettings() {
            const results = await getCurrentUser()
            const { firstName, lastName, emailAddress, role } = results
            setUserDetails({
                firstName,
                lastName,
                emailAddress,
                role,
            })
        }
        asyncFetchProfileSettings()
    }, [])

    return (
        <Box
            className={clsx(classes.root, className)}
            className={classes.contentBox}
            {...rest}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: isMobile ? 1 : 2,
                }}
            >
                <QRLogin />
                <GeneralSettings user={user} />
                <FieldOfWorkSettings user={user} />
                {user.role == 2 && <OrganizationSetting />}
                <Password />
            </Box>
        </Box>
    )
}

General.propTypes = {
    className: PropTypes.string,
}

export default General
