import React, { useState, useEffect, useRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
    getScrollableAncestors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { DragOverlay } from '@dnd-kit/core'
import { Box, Button, Paper, Typography, Link } from '@mui/material'
import { useShowContentBasedOnRoles } from '../../../hooks/useShowContentBasedOnRoles'
import IndividualStation from './IndividualStation'
import UpdateLogsheet from '../CreateOrUpdateLogsheet'
import DeleteLogsheet from '../DeleteLogsheet'
import { updateLogsheetStationOrdering } from '../actions'
import { createStationMeasurement } from '../../actions'
import { styles } from '../../../theme/styles'
import { FaRegComment } from 'react-icons/fa'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import IndividualStationDragging from './IndividualStationDragging'
import StationReordererByZone from './StationReordererByZone'
import localForage from 'localforage'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useToastContext } from '../../../context/ToastContext'

import { ReactComponent as EditIcon } from '../../../icons/buttons/edit.svg'
import { ReactComponent as GotoIcon } from '../../../icons/buttons/toRecent.svg'
import { ReactComponent as DeleteIcon } from '../../../icons/buttons/delete.svg'
import { ReactComponent as SearchIcon } from '../../../icons/buttons/search.svg'
import { ReactComponent as ReorderIcon } from '../../../icons/buttons/order.svg'
import { ReactComponent as ReorderByZoneIcon } from '../../../icons/buttons/orderByZone.svg'
import { ReactComponent as SaveIcon } from '../../../icons/buttons/save.svg'
import { ReactComponent as XIcon } from '../../../icons/buttons/x.svg'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 50,
            width: '40vw',
            minWidth: '400px',
            height: '60px', // for search box
            backgroundColor: 'white',
            borderColor: 'transparent',

            '&.Mui-focused': {
                borderColor: 'transparent', // Remove border color when focused
            },
            '&:hover fieldset': {
                borderColor: 'transparent', // Remove border color on hover
            },
        },
    },
}))

//Redners all the stations inside a logsheet and allows reordering
//If the user is an admin additional options are shown
function IndividualLogsheet({
    id,
    stations,
    stationOrdering,
    logsheetTitle,
    asyncGetIndividualLogsheet,
    updateStationOrdering,
    masterLogsheetId,
    includesAllStations,
    stationIdToScroll,
    zoneId,
}) {
    const [displayUpdateLogsheet, setDisplayUpdateLogsheet] = useState(false)
    const [displayDeleteLogsheet, setDisplayDeleteLogsheet] = useState(false)
    const [
        displayReorderingStationsByZone,
        setDisplayReorderingStationsByZone,
    ] = useState(false)
    const [initialStations, setInitialStations] = useState([])
    const [reorderableStations, setReorderableStations] = useState([])
    const [isDragging, setIsDragging] = useState(false)
    const [isReorderingStations, setIsReorderingStations] = useState(false)
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()
    const [searchBar, setSearchBar] = useState('')
    const [submittedStations, setSubmittedStations] = useState([])
    const [recentMeasurements, setRecentMeasurements] = useState([])

    const listRef = useRef()
    const sizeMap = useRef({})
    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size }
        listRef.current.resetAfterIndex(index)
    }, [])
    // 200 is the plot height
    const getSize = (index) => Math.max(sizeMap.current[index], 200) + 46 || 250

    const classes = useStyles()
    const toastContext = useToastContext()

    const [activeStationIndex, setActiveStationIndex] = useState(0);
    const stationRefs = useRef([]);

    // Add this state to track if we're submitting
    const [isSubmitting, setIsSubmitting] = useState(false);
    const scrollPositionRef = useRef(null); // Add this to track scroll position

    // Add this state to track the most recently submitted station
    const [justSubmittedStationId, setJustSubmittedStationId] = useState(null);

    useEffect(() => {
        const sortedInitialStations = stations && [
            ...stations.sort((a, b) => {
                return stationOrdering?.[a.id] - stationOrdering?.[b.id]
            }),
        ]

        setInitialStations(sortedInitialStations)
        setReorderableStations(sortedInitialStations)
    }, [stations, stationOrdering])

  
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (active.id !== over.id) {
            setReorderableStations((items) => {
                const activeIndex = items.findIndex((el) => el.id === active.id)
                const overIndex = items.findIndex((el) => el.id === over.id)

                return arrayMove(items, activeIndex, overIndex)
            })
        }

        setIsDragging(false)
    }

    const handleSubmitReorder = async () => {
        localForage.removeItem('IndexNum' + id)
        const orderingJSON = {}
        for (const [index, station] of reorderableStations.entries()) {
            orderingJSON[station.id] = index + 1
        }

        await updateLogsheetStationOrdering(id, orderingJSON).then(() =>
            updateStationOrdering(orderingJSON)
        )
    }

    const getScrollTo = () => {
        localForage.getItem('IndexNum' + id).then((val) => {
            if (val) {
                scrollToStation(val)
            }
        })
    }

    const scrollToStation = (index) => {
        if (index < reorderableStations.length) {
            try {
                // Only scroll using the list reference
                listRef.current.scrollToItem(index, 'center');
                
                // Try to find and focus input field
                const stationInput = document.querySelector(
                    `#station-input-${reorderableStations[index].id}`
                );
                
                if (stationInput && !stationInput.disabled && !stationInput.readOnly) {
                    stationInput.focus();
                }
            } catch (error) {
                console.error('Error focusing station:', error);
            }
        } else {
            listRef.current.scrollToItem(0, 'start');
        }
    };

    const scrollToUrlStation = () => {
        // if (stationIdToScroll && listRef.current) {
        //     const index = reorderableStations.findIndex(
        //         (obj) => obj.id == stationIdToScroll
        //     )
        //     scrollToStation(index)
        // }
        return
    }

    const searchBarEntered = (stationTitle) => {
        if (stationTitle) {
            const index = reorderableStations.findIndex(
                (station) => station.stationTitle === stationTitle
            )
            if (index !== -1) {
                scrollToStation(index)
            }
        }
    }

    const handleStationClick = (index, id) => {
        setActiveStationIndex(index);
        // Update URL without triggering scroll
        const newUrl = `/logsheets/${masterLogsheetId}/logsheet/${id}/${index}`;
        window.history.pushState(null, '', newUrl);
    };

    const handleStationSubmit = (currentIndex) => {
        // Don't proceed if we're at the last station
        if (currentIndex >= reorderableStations.length - 1) {
            return;
        }

        // Find next non-broken station
        let nextIndex = currentIndex + 1;
        while (
            nextIndex < reorderableStations.length && 
            reorderableStations[nextIndex].broken
        ) {
            nextIndex++;
        }

        // Only update active station if we found a valid next station
        if (nextIndex < reorderableStations.length) {
            setActiveStationIndex(nextIndex);
        }
    };

    const handleStationSubmission = async (stationId, value, comment) => {
        try {
            setIsSubmitting(true);

            const submittedMeasurement = await createStationMeasurement(
                zoneId,
                stationId,
                value,
                id,
                comment
            );

            // Update states
            setRecentMeasurements(prev => [
                ...prev,
                { measurementValue: value, createdAt: submittedMeasurement.createdAt }
            ]);

            setSubmittedStations(prev => [
                ...prev.filter(station => station.stationId !== stationId),
                { 
                    stationId, 
                    value,
                    submittedTime: submittedMeasurement.createdAt 
                }
            ]);

            // Find current index and move to next station
            const currentIndex = reorderableStations.findIndex(
                station => station.id === stationId
            );
            
            if (currentIndex !== -1) {
                // Move to next station
                handleStationSubmit(currentIndex);
                
                // This is now the only place where scrolling should happen
                listRef.current?.scrollToItem(currentIndex + 1, 'center');
            }

            toastContext.sendToast('Measurement saved successfully', 'success');

        } catch (error) {
            console.error('Error submitting measurement:', error);
            toastContext.sendToast('Error saving measurement', 'error');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderListItem = ({ index, style }) => {
        const station = reorderableStations[index];
        return (
            <IndividualStation
                key={index}
                index={index}
                {...station}
                zoneTitle={logsheetTitle}
                isReorderingStations={isReorderingStations}
                asyncReloadZone={asyncGetIndividualLogsheet}
                stationId={station.id}
                setSize={setSize}
                setFocusedStation={scrollToStation}
                virtualizedListStyles={style}
                masterLogsheetId={masterLogsheetId}
                logsheetId={id}
                // isStationIdInUrl={stationIdToScroll}
                onSubmit={(stationId, value, comment) => {
                    handleStationSubmission(stationId, value, comment);
                }}
                submittedStations={submittedStations}
                recentMeasurements={recentMeasurements}
                isActive={index === activeStationIndex}
                onClick={(id) => handleStationClick(index, id)}
            />
        )
    }

    return (
        <Box sx={styles.innerContainer}>
            <Box sx={styles.buttonList}>
                {displayUpdateLogsheet && (
                    <UpdateLogsheet
                        logsheetId={id}
                        includesAllStations={includesAllStations}
                        masterLogsheetId={masterLogsheetId}
                        initialValues={{
                            logsheetTitle: logsheetTitle,
                            stationIds: stations
                                .map((station) => station.id)
                                .reduce((result, id) => {
                                    result[id] = 1
                                    return result
                                }, {}),
                        }}
                        setDisplayModal={setDisplayUpdateLogsheet}
                        operation="Update"
                        onSuccess={asyncGetIndividualLogsheet}
                    />
                )}

                <ShowContentBasedOnRolesWrapper
                    roles={[
                        'admin',
                        'organization_admin',
                        'organization_superuser',
                    ]}
                >
                    <div
                        className="normalButton"
                        onClick={() => setDisplayUpdateLogsheet(true)}
                    >
                        <div className="buttonIconBlock">
                            <EditIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Update</div>
                            <div className="buttonTextSmallerBlock">
                                logsheet
                            </div>
                        </div>
                    </div>
                </ShowContentBasedOnRolesWrapper>
                {/* Only available to admins */}
                {displayDeleteLogsheet && (
                    <DeleteLogsheet
                        logsheetId={id}
                        masterLogsheetId={masterLogsheetId}
                        setDisplayModal={setDisplayDeleteLogsheet}
                    />
                )}

                {!includesAllStations && (
                    <ShowContentBasedOnRolesWrapper
                        roles={[
                            'admin',
                            'organization_admin',
                            'organization_superuser',
                        ]}
                    >
                        <div
                            className="normalButton"
                            onClick={() => {
                                setDisplayDeleteLogsheet(true)
                            }}
                        >
                            <div className="buttonIconBlock">
                                <DeleteIcon />
                            </div>
                            <div className="buttonTextBlock">
                                <div className="buttonTextBiggerBlock">
                                    Delete
                                </div>
                                <div className="buttonTextSmallerBlock">
                                    logsheet
                                </div>
                            </div>
                        </div>
                    </ShowContentBasedOnRolesWrapper>
                )}

                {!isReorderingStations && (
                    <div className="normalButton" onClick={() => getScrollTo()}>
                        <div className="buttonIconBlock">
                            <GotoIcon />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">Go To</div>
                            <div className="buttonTextSmallerBlock">
                                most recent entry
                            </div>
                        </div>
                    </div>
                )}

                {!isReorderingStations && (
                    <Link
                        component={RouterLink}
                        className="normalButton"
                        to={`/logsheets/${masterLogsheetId}/logsheet/${id}/comments`}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        <div className="buttonIconBlock">
                            <FaRegComment size={30} />
                        </div>
                        <div className="buttonTextBlock">
                            <div className="buttonTextBiggerBlock">
                                Comments
                            </div>
                            <div className="buttonTextSmallerBlock">
                                logsheet
                            </div>
                        </div>
                    </Link>
                )}

                {!isReorderingStations && (
                    <Autocomplete
                        id="search-bar"
                        options={reorderableStations.map(
                            (station) => station.stationTitle
                        )}
                        getOptionLabel={(option) => option}
                        className={classes.autocomplete}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search"
                                variant="outlined"
                                value={searchBar}
                                onChange={(e) => setSearchBar(e.target.value)}
                                style={{
                                    width: '100%',
                                    minWidth: '30px',
                                }}
                                InputLabelProps={{
                                    style: {
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        paddingLeft: '10px',
                                    },
                                }}
                            />
                        )}
                        onChange={(event, val) => searchBarEntered(val)}
                    />
                )}
            </Box>
            <div className="titleBig">{logsheetTitle}</div>

            <Paper
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    gap: 3,
                    zIndex: 1299,
                    padding: '7px',
                    paddingLeft: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    boxShadow: 'none',
                }}
            >
                {isReorderingStations ? (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                        }}
                    >
                        <div
                            className="normalButton normalButtonSmaller"
                            onClick={() => {
                                handleSubmitReorder()
                                setIsReorderingStations(false)
                            }}
                        >
                            <div className="buttonIconBlock">
                                <SaveIcon />
                            </div>
                            <div className="buttonTextBlock">
                                <div className="buttonTextBiggerBlock">
                                    Save
                                </div>
                            </div>
                        </div>
                        <div
                            className="normalButton normalButtonSmaller"
                            onClick={() => {
                                setIsReorderingStations(false)
                                setReorderableStations(initialStations)
                            }}
                        >
                            <div className="buttonIconBlock">
                                <XIcon />
                            </div>
                            <div className="buttonTextBlock">
                                <div className="buttonTextBiggerBlock">
                                    Cancel
                                </div>
                            </div>
                        </div>
                        {/* <ScreenScrollerButtons selector=".virtualList" /> */}
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                        }}
                    >
                        {reorderableStations.length > 0 && includesAllStations && (
                            <div
                                className="normalButton normalButtonSmaller "
                                onClick={() => setIsReorderingStations(true)}
                            >
                                <div className="buttonIconBlock">
                                    <ReorderIcon />
                                </div>
                                <div className="buttonTextBlock">
                                    <div className="buttonTextBiggerBlock">
                                        Reorder
                                    </div>

                                    <div className="buttonTextSmallerBlock">
                                        logsheets
                                    </div>
                                </div>
                            </div>
                        )}

                        {displayReorderingStationsByZone && (
                            <StationReordererByZone
                                logsheetId={id}
                                stations={initialStations}
                                setDisplayModal={
                                    setDisplayReorderingStationsByZone
                                }
                                setStationOrdering={updateStationOrdering}
                            />
                        )}

                        {Array.isArray(reorderableStations) &&
                            reorderableStations.length > 1 &&
                            includesAllStations && (
                                <div
                                    className="normalButton normalButtonSmaller "
                                    onClick={() =>
                                        setDisplayReorderingStationsByZone(true)
                                    }
                                >
                                    <div className="buttonIconBlock">
                                        <ReorderByZoneIcon />
                                    </div>
                                    <div className="buttonTextBlock">
                                        <div className="buttonTextSmallerBlock">
                                            Reorder stations
                                        </div>
                                        <div className="buttonTextBiggerBlock">
                                            by Zone
                                        </div>
                                    </div>
                                </div>
                            )}
                    </Box>
                )}
            </Paper>
            {/* </div> */}

            <div
                style={{
                    height: '100%',
                }}
            >
                {Array.isArray(reorderableStations) &&
                    reorderableStations.length > 0 && (
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                            onDragStart={({ active }) => {
                                setIsDragging(active.id)
                            }}
                        >
                            <SortableContext
                                items={reorderableStations}
                                strategy={verticalListSortingStrategy}
                            >
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            ref={(node) => {
                                                listRef.current = node
                                                scrollToUrlStation()
                                            }}
                                            height={height}
                                            itemCount={
                                                reorderableStations.length
                                            }
                                            itemSize={(index) => getSize(index)}
                                            width={width}
                                            className="virtualList"
                                            style={{
                                                paddingBottom: '70px',
                                                scrollBehavior: 'smooth',
                                            }}
                                        >
                                            {renderListItem}
                                        </List>
                                    )}
                                </AutoSizer>
                            </SortableContext>

                            {createPortal(
                                isDragging !== null && (
                                    <DragOverlay>
                                        <IndividualStationDragging
                                            {...reorderableStations.find(
                                                (station) =>
                                                    station.id === isDragging
                                            )}
                                            zoneTitle={logsheetTitle}
                                            stationId={isDragging}
                                        />
                                    </DragOverlay>
                                ),
                                document.getElementById('root')
                            )}
                        </DndContext>
                    )}
            </div>
            {/* </Paper> */}
        </Box>
    )
}

export default IndividualLogsheet
