import React from 'react'
import localforage from 'localforage'
// import MomentUtils from '@date-io/moment'
import { ThemeProvider } from '@mui/material'
// import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import useSettings from 'src/hooks/useSettings'
import { createTheme } from 'src/theme'
import { ToastProvider } from './context/ToastContext'
import { SessionMeasurementsProvider } from './context/SessionMeasurementsContext'
import { getAllLogsheetDataForOfflineUse } from './views/Logsheets/actions'
import { AuthProvider } from 'oidc-react'
import RouteRenderer from 'src/RouteRenderer'
import FeedbackButton from './components/FeedbackButton'
// import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";

// doesn't seem to work
// Amplify.configure({
//     //Auth is the same as before
//     //   Auth: {
//     //     region: "us-east-1",
//     //     userPoolId: "us-east-1_T2ZfRqx59",
//     //     userPoolWebClientId: "61l5lp494qnb60ek6h0ohonspp",
//     //   },
//     // Add in our new API, "name" can be whatever we want
//     API: {
//         endpoints: [
//             {
//                 name: 'limis',
//                 endpoint:
//                     'https://ghjq555hnb.execute-api.us-east-1.amazonaws.com/',
//             },
//         ],
//     },
// })

const App = () => {
    const { settings } = useSettings()
    const theme = createTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    })

    //config object
    const config = {
        authority: process.env.REACT_APP_OIDC_AUTHORITY,
        clientId: process.env.REACT_APP_CLIENT_ID,
        redirectUri: `${
            process.env.REACT_APP_PUBLIC_URL
                ? process.env.REACT_APP_PUBLIC_URL + 'callback'
                : 'http://localhost:3000/callback'
        }`,
        responseType: 'code',
        scope: 'openid aws.cognito.signin.user.admin',
        metadata: {
            authorization_endpoint: `https://limis.auth.us-east-1.amazoncognito.com/oauth2/authorize`,
            token_endpoint: `https://limis.auth.us-east-1.amazoncognito.com/oauth2/token`,
            userinfo_endpoint: `https://limis.auth.us-east-1.amazoncognito.com/oauth2/userInfo`,
            login_endpoint: `https://limis.auth.us-east-1.amazoncognito.com/login`,
            end_session_endpoint: `https://limis.auth.us-east-1.amazoncognito.com/logout?client_id=${
                process.env.REACT_APP_CLIENT_ID
            }&redirect_uri=${
                process.env.REACT_APP_PUBLIC_URL
                    ? process.env.REACT_APP_PUBLIC_URL + 'callback'
                    : 'http://localhost:3000/callback'
            }&response_type=code`,
            revoke_endpoint: `https://limis.auth.us-east-1.amazoncognito.com/oauth2/revoke`,
        },
    }

    //const called to cache logsheets from the database to local forage
    //If there is connection and twelve hours has passed since the last caching
    //then all logsheets with stations and measurments from the database will be uploaded to local forage
    // const cacheAllLogsheets = async () => {
    //     try {
    //         const loadedAllLogsheetsTime = await localforage.getItem('loadedAllLogsheetsTime')
    //         const TWELVE_HOURS_IN_MILLISECONDS = 1000 * 60 * 60 * 12
            
    //         if (
    //             navigator.onLine &&
    //             (!loadedAllLogsheetsTime ||
    //                 Date.now() > loadedAllLogsheetsTime + TWELVE_HOURS_IN_MILLISECONDS)
    //         ) {
    //             try {
    //                 const logsheetDataForOfflineUse = await getAllLogsheetDataForOfflineUse()
                    
    //                 // Use Promise.all to handle all storage operations together
    //                 await Promise.all([
    //                     localforage.setItem(
    //                         'allLogsheets',
    //                         logsheetDataForOfflineUse.logsheetsWithStationsAndMeasurements
    //                     ),
    //                     localforage.setItem(
    //                         'logsheetOrdering',
    //                         logsheetDataForOfflineUse.logsheetOrdering
    //                     ),
    //                     localforage.setItem(
    //                         'allLogsheetStationOrdering',
    //                         logsheetDataForOfflineUse.allLogsheetStationOrdering
    //                     ),
    //                     localforage.setItem(
    //                         'loadedAllLogsheetsTime',
    //                         Date.now()
    //                     )
    //                 ])
    //             } catch (networkError) {
    //                 console.error('Failed to fetch or store logsheet data:', networkError)
    //                 // Don't update loadedAllLogsheetsTime so it will try again next time
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error in cacheAllLogsheets:', error)
    //     }
    // }

    React.useEffect(() => {
        if (!('serviceWorker' in navigator)) {
            return
        }

        let newWorkerStateChangeCleanup
        let currentWorkerUpdateChangeCleanup
        navigator.serviceWorker
            .register('/service-worker.js')
            .then((reg) => {
                const updateFoundListener = () => {
                    const newWorker = reg.installing
                    const stateChangeListener = () => {
                        if (
                            newWorker.state === 'installed' &&
                            navigator.serviceWorker.controller
                        ) {
                            window.location.reload(true)
                        }
                    }

                    newWorker.addEventListener(
                        'statechange',
                        stateChangeListener
                    )

                    function cleanup() {
                        newWorker.removeEventListener(
                            'statechange',
                            stateChangeListener
                        )
                    }

                    newWorkerStateChangeCleanup = cleanup
                }

                reg.addEventListener('updatefound', updateFoundListener)

                function cleanup() {
                    reg.removeEventListener('updatefound', updateFoundListener)
                }

                currentWorkerUpdateChangeCleanup = cleanup
            })
            .catch((err) =>
                console.error('Service Worker registration failed: ', err)
            )

        return () => {
            newWorkerStateChangeCleanup()
            currentWorkerUpdateChangeCleanup()
        }
    }, [])

    // React.useEffect(() => {
    //     // Cache all logsheets every 12 hours
    //     // Recheck time passed on startup, every hour, and whenever user reconnects to internet
    //     cacheAllLogsheets()
    //     const allLogsheetsCachedHourlyCheck = setInterval(
    //         cacheAllLogsheets,
    //         1000 * 60 * 60
    //     )
    //     window.addEventListener('online', cacheAllLogsheets)
    //     return () => {
    //         window.removeEventListener('online', cacheAllLogsheets)
    //         clearInterval(allLogsheetsCachedHourlyCheck)
    //     }
    // }, [])

    return (
        // AuthProvider is an external library used for user authentication specified by config
        <AuthProvider {...config}>
            {/* //Sets up state through a reducer to keep track of session measurments and passes the ability to view and change state to child components */}
            <SessionMeasurementsProvider>
                {/* Uses the reducer hook to provide child element with the ability to make toast notifications apear on the main screen */}
                <ToastProvider>
                    {/* Creates a theme through mui */}
                    <ThemeProvider theme={theme}>
                        {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
                        {/* Handles api requests and if the user is signed in or not. It returns a Router to display the UI*/}
                        <RouteRenderer />
                        <FeedbackButton />
                        {/* </MuiPickersUtilsProvider> */}
                    </ThemeProvider>
                </ToastProvider>
            </SessionMeasurementsProvider>
        </AuthProvider>
    )
}

export default App
