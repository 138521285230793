import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Fab,
    Typography,
} from '@mui/material'
import FeedbackIcon from '@mui/icons-material/Feedback'
import { useToastContext } from 'src/context/ToastContext'
import axios from 'axios'

const FeedbackButton = () => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')

    const toastContext = useToastContext()

    const handleSubmit = async () => {
        try {
            await axios.post('/api/feedback', {
                message,
            })
            toastContext.sendToast('Feedback sent successfully')
            setOpen(false)
            setMessage('')
        } catch (error) {
            toastContext.sendToast('Error sending feedback', 'error')
        }
    }

    return (
        <>
            <Fab
                color="primary"
                onClick={() => setOpen(true)}
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    zIndex: 999999,
                }}
            >
                <FeedbackIcon />
            </Fab>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>Send Feedback</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" sx={{ mb: 2 }}></Typography>
                    <TextField
                        autoFocus
                        multiline
                        rows={4}
                        fullWidth
                        label="Your feedback"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!message.trim()}>
                        Send Feedback
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FeedbackButton
